<template>
  <div class="w-full h-full bg-white overflow-y-auto containerHead">
    <div
      class="w-full maxwidthscreen mx-auto"
      style="min-height: calc(100vh - 80px)"
    >
      <template v-if="this.options == 'applied-elective'">
        <div class="flex flex-col gap-6 p-10">
          <div class="flex justify-center w-full py-8">
            <img
              src="../../assets/jain-logo.png"
              alt="lots-sub-logo"
              class="h-32"
            />
          </div>
          <h1 class="uppercase font-bold text-3xl"><span class="text-primary">jain online</span> <br>test for <br>Scholarship</h1>
          <p class="text-xl font-medium">
            Thank you for choosing the {{ this.university_name }}.
          </p>
          <p class="text-xl font-medium">
            That’s a great choice! Our counselors will get in touch with you to
            <br />
            provide further assistance with the scholarship.
          </p>
          <p class="text-xl font-medium">
            Refer a friend & earn! <br />
            Your friend's career too, is as important as yours.
          </p>
          <div class="mx-auto flex justify-between gap-4">
            <router-link :to="{ name: 'DashboardView' }">
              <button
                class="h-10 bg-[#fec52d] flex gap-4 items-center justify-center p-4 rounded-md font-medium"
              >
                <img
                  src="../../assets/back-button.png"
                  alt="back-button"
                  class="h-6"
                />
                GO BACK TO HOME
              </button>
            </router-link>
            <!-- <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScKxO_Ulu-tVXj5rEl3K0DSCpbbHKQShUTDThMYgSkmz4Drrg/viewform"
              target="_blank"
            >
              <button
                class="h-10 bg-[#FEC52D] flex gap-4 items-center justify-center p-4 rounded-md font-medium"
              >
              <img
                  src="../../assets/url.png"
                  alt="back-button"
                  class="h-6"
                />
                Refer now
              </button>
            </a> -->
          </div>
        </div>
      </template>
      <template v-else-if="this.options == 'download-brochure'">
        <div class="flex flex-col gap-6 p-10">
          <div class="h-20 pl-20">
            <img
              src="../../assets/logo3.png"
              alt="Lots-main-logo"
              class="h-20"
            />
          </div>
          <div class="flex justify-center w-full py-8">
            <img
              src="../../assets/lots-new-logo3.png"
              alt="lots-sub-logo"
              class="h-32"
            />
          </div>
          <p class="text-xl font-medium">
            Thank you for downloading the brochure.
          </p>
          <p class="text-xl font-medium">
            That’s a great choice! Our counselors will get in touch with you to
            <br />
            provide further assistance with the scholarship.
          </p>
          <div class="mx-auto">
            <router-link :to="{ name: 'DashboardView' }">
              <button
                class="h-10 bg-[#FEC52D] flex gap-4 items-center justify-center p-4 rounded-md font-medium"
              >
                <img
                  src="../../assets/back-button.png"
                  alt="back-button"
                  class="h-6"
                />
                GO BACK TO HOME
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col gap-6 p-10">
          <section class="flex items-center h-full sm:p-16">
            <div
              class="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-40 h-40 dark:text-gray-600"
              >
                <path
                  fill="currentColor"
                  d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
                ></path>
                <rect
                  width="176"
                  height="32"
                  x="168"
                  y="320"
                  fill="currentColor"
                ></rect>
                <polygon
                  fill="currentColor"
                  points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
                ></polygon>
                <polygon
                  fill="currentColor"
                  points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
                ></polygon>
              </svg>
              <p class="text-3xl">Sorry, we couldn't find this page.</p>

              <button
                @click="this.$router.go(-1)"
                class="h-10 bg-[#FEC52D] flex gap-4 items-center justify-center p-4 rounded-md font-medium"
              >
                <img
                  src="../../assets/back-button.png"
                  alt="back-button"
                  class="h-6"
                />
                GO BACK
              </button>
            </div>
          </section>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankyouView",
  props: ["options"],
  data() {
    return {
      university_name: "",
    };
  },
  created() {
    if (sessionStorage.getItem("slt_uni")) {
      this.university_name = sessionStorage.getItem("slt_uni");
    }
  },
  beforeRouteLeave() {
    if (sessionStorage.getItem("slt_uni")) {
      sessionStorage.removeItem("slt_uni");
    }
  },
};
</script>

<style></style>
