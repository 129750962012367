<template lang="">
  <div class="w-full h-full bg-white">
    <div class="maxwidthscreen w-full h-full flex mx-auto">
      <!-- Left Navigation -->

      <LeftNavigation currentTap="Programs" />

      <div
        class="containerHead w-full flex flex-col gap-4 max-h-screen overflow-y-auto"
      >
        <!-- 1 -->
        <div
          class="w-full h-[80px] bg-white p-4 shadow-lg flex justify-between items-center gap-5 sticky top-0 z-50 block md:hidden"
        >
          <div class="visible md:invisible">
            <img src="../../assets/logo-black.png" class="w-[100px]" />
          </div>
          <div class="flex items-center justify-end">
            <button
              class="block md:hidden px-3 text-black"
              type="button"
              id="navBarIcon"
            >
              <i class="fa-solid fa-bars text-3xl"></i>
            </button>
          </div>
        </div>
        <div class="flex flex-wrap gap-4 justify-center mt-6">
          <div class="w-full mx-2 flex flex-col gap-3">
            <div
              class="flex flex-col md:flex-row gap-2 md:gap-0 items-center justify-between mt-2 px-8"
            >
              <h1 class="text-start text-2xl text-primary font-semibold">
                <span class="text-black">Our</span> Programs
              </h1>
              <div class="flex flex-col items-center md:flex-row gap-2">
                <!-- Filter -->
                <!-- <select
                  name=""
                  id=""
                  ref="selectUniversity"
                  @change="universitiesListFilter"
                  class="form-select appearance-none block py-2 text-sm font-normal text-gray-400 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-[#FEC52D] border-[#e5e7eb] rounded-md shadow-md focus:outline-none w-[80%] md:w-64"
                >
                  <option
                    value="All Universities"
                    :selected_val="this.program_select"
                    class="text-gray-400"
                  >
                    All Universities
                  </option>
                  <option
                    v-for="university in this.universitiesList"
                    :key="university"
                    class="text-gray-400"
                    :value="university.name"
                    :selected_val="university.id"
                  >
                    {{ university.name }}
                  </option>
                </select> -->
                <div class="relative w-full flex justify-center">
                  <input
                    type="search"
                    v-model="search_value"
                    class="appearance-none block py-2 text-sm font-normal text-gray-400 bg-white bg-clip-padding bg-no-repeat focus:ring-0 focus:border-[#FEC52D] border-[#e5e7eb] rounded-md shadow-md focus:outline-none w-[80%] md:w-64"
                    placeholder="Search Elective"
                  />
                  <i
                    class="fa-solid fa-magnifying-glass absolute right-10 md:right-[15px] top-1/2 -translate-y-1/2"
                  ></i>
                </div>
              </div>
            </div>
            <!-- 1 -->
            <div class="w-full bg-black">
              <ul
                class="flex h-10 text-lg justify-evenly w-full items-center text-white cursor-pointer"
              >
                <li
                  class="border px-4 w-[50%] h-full flex items-center justify-center font-medium border-r-1 relative"
                  @click="programCall('Masters')"
                  :class="[
                    this.select_prog == 'Masters'
                      ? 'bg-primary1 selectOptions text-black'
                      : '',
                  ]"
                >
                  Masters
                </li>
                <li
                  class="border px-4 w-[50%] h-full flex items-center justify-center font-medium border-r-1 relative"
                  @click="programCall('Bachelors')"
                  :class="[
                    this.select_prog == 'Bachelors'
                      ? 'bg-primary1 selectOptions text-black'
                      : '',
                  ]"
                >
                  Bachelors
                </li>
              </ul>
            </div>
            <!-- 2 -->
            <div
              class="flex flex-col md:flex-row w-full border mt-2"
              style="height: calc(100vh - 146px)"
            >
              <!-- 1 left -->
              <div class="w-full md:w-44 border">
                <ul
                  class="flex flex-row justify-start md:flex-col gap-4 font-medium p-2 md:justify-evenly overflow-auto"
                >
                  <template
                    v-for="program in this.programs_list"
                    ::key="program"
                  >
                    <li
                      class="p-2 h-10 md:h-16 flex items-center justify-center cursor-pointer"
                      @click="electiveCall(program.id)"
                      :class="
                        this.program_select == program.id
                          ? 'bg-primary1'
                          : 'bg-white'
                      "
                    >
                      {{ program.name }}
                    </li>
                  </template>
                </ul>
              </div>
              <!-- 2nd content -->
              <div class="containerHead w-full overflow-y-auto">
                <!-- elective list along with university -->
                <div class="flex justify-end w-[95%] items-center mt-2">
                  <span
                    class="text-end py-1 border px-2 bg-primary1 rounded-md font-medium"
                    >Elective Count : {{ this.filteredData.length }}</span
                  >
                </div>
                <div
                  v-if="this.electiveList != ''"
                  class="flex flex-wrap justify-evenly gap-4 p-4"
                >
                  <template
                    v-for="elective in this.filteredData"
                    ::key="elective"
                  >
                    <div
                      class="w-64 border border-gray-300 min-h-36 h-[300px] rounded-md p-2 text-start flex flex-col gap-2"
                    >
                      <div
                        class="border-b-2 border-gray-200 py-4 h-1/2 flex flex-col gap-2 items-start"
                      >
                        <img
                          :src="elective.university.logo"
                          alt=""
                          class="w-32 object-cover"
                        />
                        <h1 class="px-2 text-sm font-medium capitalize">
                          {{ elective.elective.electives }}
                        </h1>
                      </div>
                      <div
                        class="flex flex-col items-center justify-center py-4 gap-3 h-1/2"
                      >
                        <div>
                          <button
                            v-if="
                              this.appliedElective.includes(elective.id) ==
                              false
                            "
                            class="px-4 py-1 border bg-primary1 rounded-md font-medium"
                            @click="applyElective(elective.id,elective.university.name)"
                          >
                            Apply Now
                          </button>
                          <button
                            v-else
                            class="px-4 py-1 border bg-primary1 rounded-md font-medium cursor-not-allowed"
                          >
                            Already Applied
                          </button>
                        </div>
                        <div>
                          <div
                            v-if="elective.university.brochure"
                            @click="
                              downloadElective(
                                $event,
                                elective.id,
                                elective.university.brochure.slice(4),
                                elective.university.name
                              )
                            "
                            class="px-4 py-1 border bg-primary1 rounded-md font-medium cursor-pointer"
                          >
                            <p>Download Brochure</p>
                            <p class="hidden">Loading...</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-else class="text-center py-6">No data found</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LeftNavigation from "@/components/leftNavigation.vue";
import axios from "axios";
export default {
  data() {
    return {
      select_prog: "Masters",
      programs_list: "",
      program_select: "",
      electiveList: [],

      search_value: "",
      appliedElective: [],

      selected_university: ''
    };
  },
  components: {
    LeftNavigation,
  },
  computed: {
    ...mapGetters(["userInfo", "isAuthenticated"]),
    filteredData() {
      return this.electiveList.filter((data) => {
        return data.elective.electives
          .toLowerCase()
          .includes(this.search_value.toLowerCase());
      });
    },
  },

  // computed: mapGetters(["userInfo", "isAuthenticated"]),
  async created() {
    await this.programCall("Masters");
    this.checkApplyElective();
  },
  mounted() {
    $("#navBarIcon").on("click", function () {
      $("#navbarContainer").toggleClass("open");
    });
  },
  methods: {
    ...mapActions(["logOutUser"]),
    sessionStoreUn(val){
      sessionStorage.setItem('slt_uni',val)
    },

    async checkApplyElective() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/selected-elective/list/?user__id=${this.userInfo.data.user.id}`
        )
        .then((resp) => {
          if (resp.status == 200) {
            let electiveList = resp.data.data;
            if (electiveList) {
              electiveList.forEach((element) => {
                this.appliedElective.push(element.elective.id);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    applyElective(electiveId,university_name) {
      let bodyVal = {
        user: this.userInfo.data.user.id,
        elective: electiveId,
      };
      axios
        .post(`${process.env.VUE_APP_API}/selected-elective/post/`, bodyVal)
        .then((resp) => {
          if (resp.status == 200) {
            this.sessionStoreUn(university_name)
            this.$router.push({
              name: "Thankyou",
              params: { options: "applied-elective" },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async downloadElective(el, electiveId, url, name) {
      el.target.classList.toggle("hidden");
      el.target.nextSibling.classList.toggle("hidden");

      await fetch(`https${url}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `${name}.pdf`;
          a.click();
        });

      let bodyVal = {
        user: this.userInfo.data.user.id,
        elective: electiveId,
      };
      await axios
        .post(
          `${process.env.VUE_APP_API}/selected-elective-brochure/post/`,
          bodyVal
        )
        .then((resp) => {
          this.$router.push({
            name: "Thankyou",
            params: { options: "download-brochure" },
          });
          el.target.classList.toggle("hidden");
          el.target.nextSibling.classList.toggle("hidden");
        })
        .catch((error) => {
          console.log(error);
          el.target.classList.toggle("hidden");
          el.target.nextSibling.classList.toggle("hidden");
        });
    },

    async logOut() {
      await this.logOutUser();
      if (this.isAuthenticated == false) {
        this.$router.push({ name: "Login" });
      }
    },

    async programCall(val) {
      this.search_value = "";
      this.select_prog = val;
      await axios
        .get(
          `${process.env.VUE_APP_API}/student/program_name/list/?category__category=${val}`
        )
        .then(async (resp) => {
          if (resp.data.status == 200) {
            this.programs_list = resp.data.data;
            if (this.programs_list.length > 0) {
              this.program_select = this.programs_list[0];
              await this.electiveCall(this.program_select.id);
            }
          } else {
            this.programs_list = "";
            this.electiveList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async electiveCall(val) {
      this.program_select = val;
      this.search_value = "";
      
        await axios
        .get(
          `${process.env.VUE_APP_API}/student/universities/test/list/?program__id=${val}&university__id=51`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.electiveList = resp.data.data;
            console.log(this.electiveList)
            this.electiveList = this.electiveList.sort(
              () => Math.random() - 0.5
            );
          } else {
            this.electiveList = [];
          }
        })
        // .catch((error) => {
        //   console.log(error);
        // });
    },

    async universityElectiveFilter(val) {
      console.log(val,)
      await axios
        .get(
          `${process.env.VUE_APP_API}/student/universities/test/list/?university__id=${val}&program__id=${this.program_select}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.electiveList = resp.data.data;
            this.electiveList = this.electiveList.sort(
              () => Math.random() - 0.5
            );
          } else {
            this.electiveList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


  },
};
</script>
<style scoped>
.selectOptions::after {
  content: "";
  position: absolute;
  bottom: -17px;
  right: 48%;
  border-top: 27px solid #fec52d;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
</style>
